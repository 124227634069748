var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-card',[_c('validation-observer',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"editForm",attrs:{"form":_vm.$refs.editForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{key:("input-" + _vm.key),attrs:{"fields":_vm.fields},scopedSlots:_vm._u([{key:"districtId",fn:function(item){return [_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":item.field.label,"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('n-async-single-select',{ref:item.field.key,class:errors.length > 0 ? 'is-invalid' : null,attrs:{"query":[
                    {
                      searchby: 'province_id',
                      searchoperator: '=',
                      search: _vm.data.provinceId,
                    } ],"query-condition":"OR","name":item.field.key,"repository":item.field.repository,"selection-key":item.field.selectionKey,"selection-label":item.field.selectionLabel,"localization":item.field.localization,"clearable":item.field.clearable},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('update', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route,"hide-create":true},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e(),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }